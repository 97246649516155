import { Alert, TurtleTheme, useTurtleTheme } from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
interface Props {
  messages: Array<{
    level: 'informative' | 'success' | 'warning' | 'error'
    message: string
  }>
}

export const MessageAlerts = ({ messages = [], ...others }: Props) => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme)

  return (
    <div css={styles.alertSection}>
      {messages.map((message, index) => {
        return (
          <Alert
            // django から 同じ level と message が複数回送くることは可能なので、key に index を追加
            key={`${message.level}-${message.message}-${index}`}
            type={message.level}
            {...others}
          >
            {message.message}
          </Alert>
        )
      })}
    </div>
  )
}

const createStyles = (theme: TurtleTheme) => {
  const { spacing } = theme.turtle
  return {
    alertSection: css`
      margin-bottom: ${spacing.medium[1]};
      display: flex;
      flex-direction: column;
      gap: ${spacing.small[3]};
    `,
  }
}
