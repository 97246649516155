import { ThemeProvider } from '@dmm-com/turtle-components'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Global } from '@emotion/react'
import { globalStyles } from '@/styles/tailwind-base'
import '@/styles/google-fonts.css'

import * as Sentry from '@sentry/react'

/**
 * @param Component
 * @constructor
 */
export function createRoot(Component: JSX.Element) {
  const sentryEnv = getSentryEnv()
  if (sentryEnv) {
    Sentry.init({
      dsn: 'https://f58a7a52dfeb20cc796f4fc646e3f366@o4507581026533376.ingest.us.sentry.io/4508243543261184',
      environment: sentryEnv,
      integrations: [],
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1,
      // Setting this option to true will print useful information to the console while you're setting up Sentry.
      debug: false,
      replaysOnErrorSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
    })
  }

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <Global styles={globalStyles} />
      <ThemeProvider responsiveTypography={false}>{Component}</ThemeProvider>
    </React.StrictMode>,
  )
}

const getSentryEnv = () => {
  let environment = ''
  const host = window.location.hostname
  const prdDomain = 'biz.make.dmm.com'
  const stgDomain = 'biz.stg.make.dmm.com'

  if (host === prdDomain) {
    environment = 'prd'
  }

  if (host === stgDomain) {
    environment = 'stg'
  }

  return environment
}
