/**
 *  Django の view から渡される context を React に渡すためのロジック。
 *  JavaScript へ渡す上での安全性は以下を参考にした。
 *  (https://adamj.eu/tech/2022/10/06/how-to-safely-pass-data-to-javascript-in-a-django-template/)
 *
 *  django views.py で以下のように context を渡す。
 *  context = {
 *    'name': 'dmm',
 *    'age': 18,
 *  }
 *
 *  react で以下のように context を受け取る。
 *  const { name, age } = useDjangoCtx()
 */
import { Auth } from '@/hooks/useDjangoCtx/types/customer'
import {
  AlertMessage,
  AlertMessages,
  DjangoMessage,
  DjangoMessages,
} from '@/hooks/useDjangoCtx/types/messages.ts'

type OriginalDjangoCtx<T> = T & Auth & DjangoMessages

type DjangoCtx<T> = T & Auth & AlertMessages

const djangoToAlertMessages = (djangoMessage: Array<DjangoMessage>) =>
  djangoMessage.reduce<AlertMessage[]>((acc, cur) => {
    if (cur.level === 'debug') return acc

    return [
      ...acc,
      {
        ...cur,
        level: cur.level === 'info' ? 'informative' : cur.level,
      },
    ]
  }, [])

export const useDjangoCtx = <T>(): DjangoCtx<T> => {
  const context_script_element = document.getElementById('context-data')

  // python で None が渡された場合、null になるが、フロントで扱いにくいので、undefined として型をつける。
  const context: OriginalDjangoCtx<T> = JSON.parse(
    context_script_element?.textContent || '',
  )

  return {
    ...context,
    alert_messages: djangoToAlertMessages(context.django_messages),
  }
}
