import { css } from '@emotion/react'
import {
  TurtleTheme,
  theme as originalTheme,
  useTurtleTheme,
} from '@dmm-com/turtle-components'

export const Footer = ({ ...others }: React.HTMLAttributes<HTMLDivElement>) => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme)

  return (
    <div css={styles.wrapper} {...others}>
      Copyright © since 1998 DMM All Rights Reserved.
    </div>
  )
}

const createStyles = (theme: TurtleTheme) => {
  return {
    wrapper: css`
      padding: ${theme.turtle.spacing.small[2]} 0
        ${theme.turtle.spacing.small[4]};
      justify-content: center;
      display: flex;
      color: ${originalTheme.color.dark.gray[900]};
      background-color: ${originalTheme.color.dark.gray[50]};
      font-size: ${originalTheme.fontSizes['10']};
      font-weight: ${theme.turtle.typography.body5.fontWeight};
      font-family: ${originalTheme.typography.label.fontFamily};
      width: 100%;
    `,
  }
}
